import React, { useEffect, useState } from 'react';
import { alert, callApi, parseBrToReact } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
export const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(true);
  const searchParam = new URL(window.location.href).searchParams;
  const mb_no = searchParam.get('mb_no');
  const mb_nonce = searchParam.get('mb_nonce');
  const navigate = useNavigate();

  useEffect(() => {
    const resetPassword = async () => {
      try {
        const param = {
          mb_no,
          mb_nonce,
        };

        const response = await callApi('api/platform/resetPassword', param);
        const { msg } = response;

        alert({
          content: parseBrToReact(msg),
          onOk: () => {
            navigate('/', { replace: true });
          },
        });
      } catch (e) {
        console.error(`비밀번호 초기화 에러 : ${e}`);
        alert({
          content: '비밀번호 초기화 오류',
          onOk: () => {
            navigate('/', { replace: true });
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    resetPassword();
  }, []);

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      fullscreen
    />
  );
};

import React from "react"
import style from "./alert.module.css"
import transferIcon from 'assets/images/svg_icon/transfer.svg'
import closeBlackIcon from 'assets/images/svg_icon/close_black.svg'

export const AlertMessage = (props) => {
    const { onCancelPress, onConfirmPress } = props
    return (
        <div className={style.container}>  
            <div className={style.top}>
                <img src={transferIcon} alt="" />
                <button onClick={onCancelPress}>
                    <img src={closeBlackIcon} alt="" />
                </button>
            </div>
            <div className={style.text}>아이페이, 엑셀 파일, 더존등의 데이터를 휴램프로로 이전 신청하고 사업장 데이터를 쉽게 불러오세요.</div>
            <button className={style.confirmBtn} onClick={onConfirmPress}>데이터 이관 신청 바로가기</button>
        </div>
    );
};

import React from 'react';
import styles from './dataTransfer.module.css';
import iPayIcon from '../../../../assets/images/svg_icon/IPAY_icon.svg';
import excelIcon from '../../../../assets/images/svg_icon/excel_icon.svg';
import doZoneIcon from '../../../../assets/images/svg_icon/doZone_icon.svg';

const data = [
  {
    id: 0,
    title: '아이페이',
    icon: iPayIcon,
    content: '아이페이에서 사용했던 사원정보, 급여정보 데이터를 휴램프로로 이관해요.',
    buttonTitle: '데이터 이관 신청하기',
    link: 'https://shimmer-vein-8d5.notion.site/1905807a34bf80299b53f566bd980e62'
  },
  {
    id: 1,
    title: '엑셀',
    icon: excelIcon,
    content: '커스텀 해서 사용하는 엑셀 파일을 휴램프로에서 업로드하여 사용할 수 있어요.',
    buttonTitle: '데이터 이관 신청하기',
    link: 'https://shimmer-vein-8d5.notion.site/1a45807a34bf807a9473e27e0c35995d?pvs=4'
  },
  {
    id: 2,
    title: '더존',
    icon: doZoneIcon,
    content: '더존에서 사용했던 급여대장 데이터를 휴램프로에서 업로드하여 사용할 수 있어요.',
    buttonTitle: '더존 급여대장 업로드 방법 보기',
    link: 'https://shimmer-vein-8d5.notion.site/10c5807a34bf80a1b8f8fa136101007b'
  },
]
export default function DataTransferTab() {
  return (
    <div className={styles.mainContainer}>
      {data?.map((item, index) => {
        return (
          <div className={styles.container} key={`card_list_${index}`}>
            <div className={styles.top}>
              <div className={styles.iconBox}>
                <img src={item.icon} alt="" />
              </div>
              <strong className={styles.title}>{item.title}</strong>
            </div>
            <p className={styles.content}>{item.content}</p>
            <a className={styles.confirmBtn} href={item.link} target='_blank' rel="noreferrer">{item.buttonTitle}</a>
          </div>
        );
      })}
    </div>
  );
}

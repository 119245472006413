import { Flex } from 'components/flex';
import styles from './Subscription.module.css';
import { Tab } from 'components/tab';
import { Divider, Modal, Spin, Table, Tag } from 'antd';
import { Button } from 'components/button';
import { useState, useEffect, Suspense } from 'react';
import { addCommas, callApi } from 'utils';
import { SubscriptItem } from '../../../components/subscript/subscript-item';
import { ClockDefaultIcon } from '../../../components/icon/icons/Clock-default';
import { BuildingIcon, InfoGrayIcon } from '../../../components/icon';
import dayjs from 'dayjs';
import { Tooltip } from '../../../components/tooltip';
import { LoadingOutlined } from '@ant-design/icons';

const { Column, ColumnGroup } = Table;

export const SubscriptionManage = () => {
  const [amountByAgencyModal, setAmountByAgencyModal] = useState(false);
  const [subscribeList, setSubscribeList] = useState([]);
  const [unSubscribeList, setUnSubscribeList] = useState([]);
  const [unSubscribeBasicItem, setUnSubscribeBasicItem] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [agencyInfos, setAgencyInfos] = useState({});
  const [agencyTablePagination, setAgencyTablePagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [agencyTableAttYear, setAgencyTableAttYear] = useState('');
  const [paymentList, setPaymentList] = useState([]);
  const [paymentListRows, setPaymentListRows] = useState(20);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentListLoading, setIsPaymentListLoading] = useState(false);
  const [hdmiId, setHdmiId] = useState(null);

  const getSubscriptInfo = async () => {
    const param = {
      opId: sessionStorage.getItem('userId'),
    };

    const [subscribeInfo, unSubscribeInfo, paymentInfo, paymentList] = await Promise.all([
      callApi('api/platform/billing/getSubscribe', param),
      callApi('api/platform/billing/getUnSubscribe', param),
      callApi('api/platform/billing/getPayment', param),
      callApi('api/platform/billing/getPaymentList', param),
    ]);

    return [subscribeInfo, unSubscribeInfo, paymentInfo, paymentList];
  };

  useEffect(() => {
    setIsLoading(true);

    getSubscriptInfo()
      .then(([subscribeInfo, unSubscribeInfo, paymentInfo, paymentList]) => {
        let isSubscribeInfo = false;
        if (subscribeInfo.result && !subscribeInfo.msg) {
          setSubscribeList(subscribeInfo.data);
          isSubscribeInfo = true;
        } else {
          setSubscribeList([]);
        }

        if (unSubscribeInfo.result && !unSubscribeInfo.msg) {
          let unSubscribeDataList = unSubscribeInfo.data;
          if (isSubscribeInfo) {
            unSubscribeDataList = unSubscribeDataList.filter(
              item =>
                subscribeInfo.data.find(
                  subscribeItem => subscribeItem.product_type === item.product_type
                ) === undefined
            );
          }

          //구독중인 서비스중 4insurance 있으면 제외
          let exceptionUnSubscribeList = unSubscribeDataList.filter(
            item => item.is_extra_type !== '0'
          );
          setUnSubscribeList(exceptionUnSubscribeList);

          //구독중인 서비스중 basic 있으면 제외
          const findBasicItem = unSubscribeDataList.find(item => item.is_extra_type === '0');

          if (findBasicItem) {
            setUnSubscribeBasicItem(findBasicItem);
          }
        } else {
          setUnSubscribeList([]);
          setUnSubscribeBasicItem(null);
        }

        if (paymentInfo.result && !paymentInfo.msg) {
          setPaymentInfo(paymentInfo.data);
          setHdmiId(paymentInfo.data.hdmi_id);
        } else {
          setPaymentInfo(null);
        }

        if (paymentList.result && !paymentList.msg && Array.isArray(paymentList.data)) {
          const changeFormatData = paymentList.data.map((item, index) => {
            let paymentAmount = '';
            let paymentMethod = '';
            let period = '';

            if (item.status === 'paymented') {
              paymentAmount = item.withdrawal_amt;
            } else {
              paymentAmount =
                Number(item.expected_final_amt) > 0
                  ? item.expected_final_amt
                  : item.free_expected_final_amt;
            }

            if (item.payment_method === 'CMS') {
              paymentMethod = `${item.payment_method}(자동이체)`;
            } else if (item.payment_method === 'TRANSFER') {
              paymentMethod = `계좌이체`;
            } else {
              paymentMethod = item.payment_method;
            }

            period = `${dayjs(item.periodUseStartDate).format('YYYY.MM.DD')} ~ ${dayjs(item.periodUseEndDate).format('YYYY.MM.DD')}`;

            let paymentStatus = '';

            switch (item.status) {
              case 'ready':
                paymentStatus = '결제 예정';
                break;
              case 'exclusion':
                paymentStatus = '무료 제공';
                break;
              case 'hold':
                paymentStatus = '청구 보류';
                break;
              case 'paymented':
                paymentStatus = '결제 완료';
                break;
              default:
                paymentStatus = '';
            }

            return {
              key: `payemnt_list_key_${index}_${item.hdmi_id}`,
              hdmi_id: item.hdmi_id,
              payment_date: dayjs(item.payment_date).format('YYYY.MM.DD'),
              payment_amt: item.status === 'exclusion' ? 0 : addCommas(paymentAmount),
              payment_method: paymentMethod,
              payment_status: paymentStatus,
              payment_period: period,
              payment_company_cnt: addCommas(item.total_company_cnt) + '개',
              payment_emp_cnt: addCommas(item.total_emp_cnt) + '명',
              payment_product_name: item.product_names,
              payment_number: item.paymentNumber,
            };
          });

          setPaymentList(changeFormatData);
        } else {
          setPaymentList([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (hdmiId) {
      setAgencyTablePagination({
        current: 1,
        pageSize: 10,
      });
      getAgencyInfos();
    }
  }, [hdmiId]);

  const getAgencyInfos = async (page, pageSize) => {
    setIsPaymentListLoading(true);
    const param = {
      opId: sessionStorage.getItem('userId'),
      hdmi_id: hdmiId,
      page: page,
      page_size: pageSize,
    };

    const response = await callApi('api/platform/billing/getPaymentDetailByCompany', param);

    if (response.result && response.data) {
      setAgencyTablePagination({
        current: response.data.page,
        pageSize: response.data.page_size,
        total: response.data.total_cnt,
      });

      if (response.data.list.length > 0) {
        const attYear = response.data.list[0].payment_list[0].hsm_yyyy;
        const attMonth = response.data.list[0].payment_list[0].hsm_mm;

        setAgencyTableAttYear(`${attYear}-${attMonth}`);
      }

      const changeFormatData = response.data.list.map((item, index) => {
        const paymentListData = item.payment_list.map((payment, index) => {
          const basicAmt =
            payment.total_free_basic_amt > 0
              ? payment.total_free_basic_amt
              : payment.total_basic_amt;
          const insuranceAmt =
            payment.total_free_insurance_amt > 0
              ? payment.total_free_insurance_amt
              : payment.total_insurance_amt;

          if (index === 0) {
            return {
              prev_month_emp_cnt: addCommas(payment.emp_cnt) + '명',
              prev_month_basic_amt: addCommas(basicAmt) + '원',
              prev_month_insurance_mat: addCommas(insuranceAmt) + '원',
              prev_month_total_amt: addCommas(payment.total_amt) + '원',
            };
          } else {
            return {
              current_month_emp_cnt: addCommas(payment.emp_cnt) + '명',
              current_month_basic_amt: addCommas(basicAmt) + '원',
              current_month_insurance_mat: addCommas(insuranceAmt) + '원',
              current_month_total_amt: addCommas(payment.total_amt) + '원',
            };
          }
        });

        const prevMonthObject = { ...paymentListData[0] };
        const currentMonthObject = paymentListData[1] ? { ...paymentListData[1] } : {};

        return {
          key: `key_${index}_${item.mb_name}`,
          mb_name: item.mb_name,
          ...prevMonthObject,
          ...currentMonthObject,
        };
      });

      setAgencyInfos({
        is_basic_service: response.data.is_basic_service,
        list: changeFormatData,
      });

      setIsPaymentListLoading(false);
    }
  };

  const tabItems = () => {
    return [
      {
        key: '1',
        label: '결제 내역',
        children: (
          <Flex direction="column">
            <Flex
              direction="column"
              alignItems="flex-start"
              style={{ marginTop: 40, width: '100%', gap: 40 }}
            >
              <div className={styles.item}>
                <h3>구독중인 서비스</h3>
                <Suspense fallback={'로딩중...'}>
                  {subscribeList.length < 1 ? (
                    <span className={styles.empty_text}>구독중인 서비스가 없어요.</span>
                  ) : (
                    <div className={styles.subscribed_item}>
                      {subscribeList.map(subscribe => {
                        return (
                          <SubscriptItem key={`subscript_id_${subscribe.hp_id}`} {...subscribe} />
                        );
                      })}
                    </div>
                  )}
                </Suspense>
              </div>
              {(unSubscribeBasicItem || unSubscribeList.length > 0) && (
                <div className={styles.item}>
                  <h3>{subscribeList.length > 0 ? '추가 서비스 구독' : '구독가능한 서비스'}</h3>
                  <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    rowGap={16}
                  >
                    {unSubscribeBasicItem && (
                      <Flex
                        className={styles.add_subscription_item}
                        direction="column"
                        rowGap={40}
                        alignItems="flex-start"
                      >
                        <SubscriptItem {...unSubscribeBasicItem} unScribe={true} />
                      </Flex>
                    )}
                    <Flex
                      className={styles.add_subscription_item}
                      direction="column"
                      rowGap={40}
                      alignItems="flex-start"
                    >
                      {unSubscribeList.map(subscribe => {
                        return (
                          <SubscriptItem
                            key={`unsubscript_id_${subscribe.hp_id}`}
                            {...subscribe}
                            unScribe
                          />
                        );
                      })}
                    </Flex>
                  </Flex>
                </div>
              )}
              <div style={{ width: '100%' }}>
                <Flex justifyContent={'space-between'}>
                  <h3>결제 금액</h3>
                  {paymentInfo?.updated_dt && (
                    <Flex>
                      {dayjs(paymentInfo?.updated_dt).format('YYYY.MM.DD(HH:mm)')} 기준
                      <Tooltip
                        className={styles.info_tooltip}
                        title={`결제 대상 변경 후 대상 사원수와 금액은 하루 뒤에 업데이트돼요.`}
                        overlayStyle={{
                          width: 250,
                        }}
                      >
                        <InfoGrayIcon size={20} />
                      </Tooltip>
                    </Flex>
                  )}
                </Flex>
                <div className={styles.wage_box}>
                  <Flex justifyContent="space-between" className={styles.wage_box_item}>
                    <Flex direction="column" rowGap={15} alignItems="flex-start">
                      <span className={styles.box_title}>대상 사원수</span>
                      <span className={styles.box_content}>
                        {paymentInfo?.total_emp_cnt || 0}명
                      </span>
                    </Flex>
                    {paymentInfo?.updated_dt && (
                      <Tooltip
                        className={styles.tooltip_box}
                        title={`${dayjs(paymentInfo?.updated_dt).format('YYYY.MM.DD(HH:mm)')} 기준`}
                      >
                        <ClockDefaultIcon size={16} />
                      </Tooltip>
                    )}
                  </Flex>
                  <Divider type="vertical" style={{ height: 45 }} />
                  <Flex
                    className={styles.wage_box_item}
                    direction="column"
                    rowGap={15}
                    alignItems="flex-start"
                  >
                    <Flex justifyContent={'space-between'} style={{ width: '100%' }}>
                      <span className={styles.box_title}>총금액</span>
                      {paymentInfo && (
                        <Flex style={{ alignSelf: 'flex-start' }}>
                          <Button
                            type="text"
                            size="sm"
                            onClick={() => {
                              if (paymentInfo?.hdmi_id) {
                                setAmountByAgencyModal(true);
                                setHdmiId(paymentInfo?.hdmi_id);
                              }
                            }}
                            style={{
                              padding: 0,
                              color: 'var(--expert-primary-purple-500, #866DEC)',
                              fontSize: '14px',
                              height: 20,
                              gap: 4,
                            }}
                          >
                            <InfoGrayIcon size={20} />
                            수임업체별 금액
                          </Button>
                          <Tooltip
                            className={styles.tooltip_box}
                            title={`${dayjs(paymentInfo?.updated_dt).format(
                              'YYYY.MM.DD(HH:mm)'
                            )} 기준`}
                          >
                            <ClockDefaultIcon size={16} />
                          </Tooltip>
                        </Flex>
                      )}
                    </Flex>
                    <div className={styles.price_box}>
                      <Flex className={styles.box_content} columnGap={8}>
                        <span>
                          {paymentInfo?.expected_final_amt
                            ? addCommas(paymentInfo?.expected_final_amt)
                            : 0}
                          원
                        </span>
                        <span className={styles.vat_amt_text}>(VAT 포함)</span>
                        {paymentInfo && paymentInfo?.free_expected_final_amt !== 0 && (
                          <Tag color="blue" className="height-16">
                            무료
                          </Tag>
                        )}
                      </Flex>
                      {paymentInfo && paymentInfo?.free_expected_final_amt !== 0 && (
                        <span className={styles.discount_price}>
                          {addCommas(paymentInfo?.free_expected_final_amt)}원
                        </span>
                      )}
                    </div>
                  </Flex>
                  <Divider type="vertical" style={{ height: 45 }} />
                  <Flex justifyContent="space-between" className={styles.wage_box_item}>
                    <Flex
                      direction="column"
                      rowGap={15}
                      alignItems="flex-start"
                      className={styles.wage_box_item}
                    >
                      <span className={styles.box_title}>결제일</span>
                      <span className={styles.box_content}>
                        {paymentInfo?.payment_date
                          ? dayjs(paymentInfo?.payment_date).format('YYYY.MM.DD')
                          : '-'}
                      </span>
                    </Flex>
                    {paymentInfo?.billing_date && (
                      <Tooltip
                        className={styles.tooltip_box_2}
                        title={`${dayjs(paymentInfo?.billing_date).format(
                          'YYYY.MM.DD'
                        )} 청구일 기준으로 결제일에 결제돼요.`}
                        overlayStyle={{
                          width: 250,
                        }}
                      >
                        <InfoGrayIcon size={20} />
                        청구일
                      </Tooltip>
                    )}
                  </Flex>
                  <Divider type="vertical" style={{ height: 45 }} />
                  <Flex
                    direction="column"
                    rowGap={15}
                    alignItems="flex-start"
                    className={styles.wage_box_item}
                  >
                    <span className={styles.box_title}>결제 대상</span>
                    <span className={styles.box_content}>{paymentInfo?.product_names || '-'}</span>
                  </Flex>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <Flex justifyContent={'space-between'} className={styles.item}>
                  <h3>결제 내역</h3>
                  <span className={styles.point_red}>
                    ※참고사항: CMS 최소 청구금액(1,000원) 미만 건의 경우,차월 청구금액과 통합하여
                    청구 처리됩니다.
                  </span>
                </Flex>
                <Table
                  dataSource={paymentList}
                  className={`${styles.table} ${styles.payment_table}`}
                  pagination={{
                    pageSize: paymentListRows,
                    onChange: (_, pageSize) => setPaymentListRows(pageSize),
                  }}
                  tableLayout={'fixed'}
                  scroll={{
                    y: 420,
                  }}
                >
                  <Column
                    title="결제 일시"
                    dataIndex={'payment_date'}
                    width={118}
                    ellipsis
                  ></Column>
                  <Column title="결제 금액" dataIndex={'payment_amt'} width={118} ellipsis></Column>
                  <Column
                    title="결제 수단"
                    dataIndex={'payment_method'}
                    width={118}
                    ellipsis
                  ></Column>
                  <Column
                    title="결제 상태"
                    className={styles.add_right_border}
                    dataIndex={'payment_status'}
                    width={118}
                    ellipsis
                  ></Column>
                  <ColumnGroup title="상세 정보" className={styles.add_right_border}>
                    <Column title={'이용 기간'} width={200} dataIndex={'payment_period'}></Column>
                    <Column
                      title={'수임업체 수'}
                      width={140}
                      dataIndex={'payment_company_cnt'}
                      render={(text, record, index) => {
                        return (
                          <Flex rowGap={8}>
                            {text}
                            <Tooltip title={`수임업체 목록 보기`}>
                              <Button
                                type="hulam_icon_button"
                                size="sm"
                                onClick={() => {
                                  if (record.hdmi_id) {
                                    setAmountByAgencyModal(true);
                                    setHdmiId(record.hdmi_id);
                                  }
                                }}
                                style={{
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <BuildingIcon size={16} />
                              </Button>
                            </Tooltip>
                          </Flex>
                        );
                      }}
                    ></Column>
                    <Column
                      title={'과금대상 사원수'}
                      width={140}
                      dataIndex={'payment_emp_cnt'}
                    ></Column>
                    <Column
                      title={'구독 서비스'}
                      width={140}
                      dataIndex={'payment_product_name'}
                      className={styles.add_right_border}
                    ></Column>
                  </ColumnGroup>
                  <Column title="결제 번호" dataIndex={'payment_number'} ellipsis></Column>
                </Table>
              </div>
            </Flex>
          </Flex>
        ),
      },
    ];
  };

  return (
    <>
      <Flex
        direction="column"
        alignItems="flex-start"
        rowGap={40}
        style={{ paddingBottom: 220, width: '100%' }}
        className={styles.manger_container}
      >
        <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>결제/구독 관리</h2>
        <Tab items={tabItems()} style={{ width: '100%' }} />
      </Flex>
      {amountByAgencyModal && (
        <Modal
          title={'수임업체별 금액'}
          open={amountByAgencyModal}
          closeIcon={false}
          width={agencyInfos.is_basic_service ? 800 : 1094}
          centered
          footer={
            <Button
              type={'line-gray'}
              size="sm"
              onClick={() => {
                setAmountByAgencyModal(false);
              }}
            >
              닫기
            </Button>
          }
          styles={{
            body: {
              padding: '40px 20px',
            },
          }}
        >
          <Table
            dataSource={agencyInfos.list}
            className={styles.table}
            pagination={{
              ...agencyTablePagination,
              onChange: (page, pageSize) => getAgencyInfos(page, pageSize),
            }}
            tableLayout={'fixed'}
            scroll={{
              y: 420,
            }}
            loading={isPaymentListLoading}
          >
            <Column title="수임업체명" dataIndex={'mb_name'} width={180} ellipsis></Column>
            {agencyInfos && !agencyInfos.is_basic_service ? (
              <>
                <ColumnGroup title={dayjs(agencyTableAttYear).format('YYYY년 MM월') + '귀속'}>
                  <Column title={'급여대장 사원수'} dataIndex={'prev_month_emp_cnt'}></Column>
                  <Column title={'기본 서비스'} dataIndex={'prev_month_basic_amt'}></Column>
                  <Column title={'4대보험료 조회'} dataIndex={'prev_month_insurance_mat'}></Column>
                  <Column
                    title={'합계'}
                    dataIndex={'prev_month_total_amt'}
                    className={styles.table_point_cell}
                  ></Column>
                </ColumnGroup>
                <ColumnGroup
                  title={dayjs(agencyTableAttYear).add(1, 'month').format('YYYY년 MM월') + '귀속'}
                  className={styles.table_left_border}
                >
                  <Column
                    title={'급여대장 사원수'}
                    dataIndex={'current_month_emp_cnt'}
                    className={styles.table_left_border}
                  ></Column>
                  <Column title={'기본 서비스'} dataIndex={'current_month_basic_amt'}></Column>
                  <Column
                    title={'4대보험료 조회'}
                    dataIndex={'current_month_insurance_mat'}
                  ></Column>
                  <Column
                    title={'합계'}
                    dataIndex={'current_month_total_amt'}
                    className={styles.table_point_cell}
                  ></Column>
                </ColumnGroup>
              </>
            ) : (
              <ColumnGroup title={dayjs(agencyTableAttYear).format('YYYY년 MM월') + '귀속'}>
                <Column
                  title={'급여대장 사원수'}
                  dataIndex={'prev_month_emp_cnt'}
                  width={140}
                ></Column>
                <Column
                  title={'기본 서비스'}
                  dataIndex={'prev_month_basic_amt'}
                  width={140}
                ></Column>
                <Column
                  title={'4대보험료 조회'}
                  dataIndex={'prev_month_insurance_mat'}
                  width={140}
                ></Column>
                <Column
                  title={'합계'}
                  dataIndex={'prev_month_total_amt'}
                  width={140}
                  className={styles.table_point_cell}
                ></Column>
              </ColumnGroup>
            )}
          </Table>
        </Modal>
      )}
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        fullscreen
      />
    </>
  );
};

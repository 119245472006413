import React, { createContext, useContext, useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import { callApi, snackBar } from 'utils';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get('userId') ? true : false);
  const [author, setAuthor] = useState(Cookies.get('author'));
  const [onBoardingClose, setOnBoardingClose] = useState(Cookies.get('onBoarding') ? true : false);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const removeCookieList = [
    'author',
    'userId',
    'accessToken',
    'saveLogin',
    'adminId',
    'adminAccessToken',
    'email',
    'opeNo',
  ];
  const [companyData, setCompanyData] = useState({});
  const [employeeData, setEmployeeData] = useState({});

  const loadLogin = () => {
    const saveLogin = Cookies.get('saveLogin');
    const author = Cookies.get('author');
    const userId = Cookies.get('userId');
    const email = Cookies.get('email');
    const opeNo = Cookies.get('opeNo');
    const access_token = Cookies.get('accessToken');
    if (saveLogin) {
      sessionStorage.setItem('userId', userId);
      sessionStorage.setItem('author', author);
      sessionStorage.setItem('accessToken', access_token);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('opeNo', opeNo);
      sessionStorage.setItem('saveLogin', saveLogin);

      setIsLoggedIn(true);
      setIsAuthReady(true);
      setAuthor(author);
    }
  };
  const login = (response, type, saveLogin) => {
    Cookies.set('author', response.type, { expires: 7 });

    Cookies.set('userId', response.userId, { expires: 7 });
    Cookies.set('accessToken', response.access_token, { expires: 7 });
    Cookies.set('email', response.email, { expires: 7 });
    Cookies.set('opeNo', response.opeNo, { expires: 7 });
    Cookies.set('saveLogin', saveLogin, { expires: 7 });

    sessionStorage.setItem('author', response.type);
    sessionStorage.setItem('accessToken', response.access_token);
    sessionStorage.setItem('userId', response.userId);
    sessionStorage.setItem('email', response.email);
    sessionStorage.setItem('opeNo', response.opeNo);
    sessionStorage.setItem('saveLogin', saveLogin);

    setIsLoggedIn(true);
    setAuthor(response.type);
  };

  const adminlogin = (response, type, saveLogin) => {
    removeCookieList.forEach(name => {
      Cookies.remove(name);
    });

    sessionStorage.clear();
    setIsLoggedIn(false);
    setAuthor(null);

    Cookies.set('author', response.type, { expires: 7 });
    Cookies.set('userId', response.userId, { expires: 7 });
    Cookies.set('accessToken', response.access_token, { expires: 7 });
    Cookies.set('saveLogin', saveLogin, { expires: 7 });

    Cookies.set('adminId', response.adminId, { expires: 7 });
    Cookies.set('adminAccessToken', response.adminAccessToken, { expires: 7 });

    sessionStorage.setItem('author', response.type);
    sessionStorage.setItem('accessToken', response.access_token);
    sessionStorage.setItem('userId', response.userId);
    sessionStorage.setItem('saveLogin', saveLogin);

    sessionStorage.setItem('adminId', response.adminId);
    sessionStorage.setItem('adminAccessToken', response.adminAccessToken);

    setIsLoggedIn(true);
    setAuthor(response.type);
    setCompanyData({
      ...companyData,
      companyName: response.companyName,
    });
  };

  const logout = () => {
    removeCookieList.forEach(name => {
      Cookies.remove(name);
    });

    sessionStorage.clear();

    setIsLoggedIn(false);
    setAuthor(null);
    setCompanyData({});
    setEmployeeData({});
    snackBar('로그아웃 되었습니다.');
  };

  const onBoardingHandler = () => {
    if (!Cookies.get('onBoarding')) {
      Cookies.set('onBoarding', 'close');
    }

    setOnBoardingClose(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      const opId = sessionStorage.getItem('userId');
      const email = sessionStorage.getItem('email');
      const opeNo = sessionStorage.getItem('opeNo');
      if (opId) {
        const postData = {
          opId: opId,
          email: email,
        };

        callApi('api/platform/viewPrivateSetting', postData).then(res => {
          if (res.result) {
            setCompanyData({
              ...res.data,
            });
          }
        });
      }

      if (opeNo !== 'undefined' && opId) {
        const postData = {
          opId: opId,
          email: email,
          opeNo: opeNo,
        };

        callApi('api/platform/viewPrivateSetting', postData).then(res => {
          if (res.result) {
            setEmployeeData(res.data);
          }
        });
      }
    }
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        author,
        isAuthReady,
        onBoardingClose,
        setOnBoardingClose,
        login,
        logout,
        onBoardingHandler,
        loadLogin,
        adminlogin,
        companyData,
        setCompanyData,
        employeeData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };

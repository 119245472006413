import { Flex, Tab } from 'components/hulam_platform';
import { useEffect, useState } from 'react';
import ProfileEditTab from './tabs/ProfileEditTab';
import { callApi, snackBar } from 'utils';
import InsuranceIntegrationTab from './tabs/InsuranceIntegrationTab';
import { useAuth } from 'AuthProvider';
import { SubscriptStatusLabel } from '../../../components/subscript/subscript-status-label';
import DataTransferTab from './tabs/dataTransferTab';
import { useLocation, useNavigate } from 'react-router-dom';

export const HulamSetting = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get("tab") || "1";

  const [activeKey, setActiveKey] = useState(defaultTab);
  const [toggleState, setToggleState] = useState({
    toggle1: false,
    toggle2: false,
    toggle3: false,
    toggle4: false,
  });
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [fISubscriptionStatus, setFISubscriptionStatus] = useState(null);

  const { author } = useAuth();

  const setToggle = (target, bool) => setToggleState({ ...toggleState, [target]: bool });

  const getSubscribeInfo = async () => {
    const param = {
      opId: sessionStorage.getItem('userId'),
    };

    const { result, msg, data } = await callApi('api/platform/billing/getSubscribe', param);

    if (result) {
      if (msg !== '구독 데이터가 없음') {
        setSubscriptionStatus(true);

        if (data) {
          const isFISubscription = data.find(item => item.product_name === '4대보험료 조회');
          setFISubscriptionStatus(isFISubscription !== undefined);
        }
      } else {
        setSubscriptionStatus(false);
        setFISubscriptionStatus(false);
      }
    } else {
      setSubscriptionStatus(false);
      setFISubscriptionStatus(false);
    }
  };

  /**@function updateProfile()
   * 프로필 수정
   */
  const updateProfile = () => {
    snackBar('프로필 정보가 수정되었습니다.');
  };

  const menuTitle = author === 'manager' ? '환경 설정' : '계정 설정';

  useEffect(() => {
    getSubscribeInfo();
  }, []);

  let tabQueryParam = queryParams.get("tab");

  useEffect(() => {
    if (Number(tabQueryParam) > 3) {
      tabQueryParam = 1;
      navigate(`/hulamSetting?tab=${tabQueryParam}`, { replace: true });
    }

    if (tabQueryParam && tabQueryParam !== activeKey) {
      setActiveKey(tabQueryParam);
    }
  }, [tabQueryParam]);

  const onChangeTab = (key) => {
    const newKey = key || "1";
    if (newKey !== activeKey) {
      setActiveKey(newKey);
      navigate(`/hulamSetting?tab=${newKey}`, { replace: true });
    }
  };

  const tabItems = () => {
    if (author === 'manager') {
      return [
        {
          key: '1',
          label: '4대 보험 연동',
          children: (
            <InsuranceIntegrationTab
              toggleState={toggleState}
              setToggle={setToggle}
              subscriptionStatus={subscriptionStatus}
              fISubscriptionStatus={fISubscriptionStatus}
            />
          ),
          forceRender: true,
        },
        {
          key: '2',
          label: '프로필 수정',
          children: <ProfileEditTab confirm={updateProfile} />,
          forceRender: true,
        },
        {
          key: '3',
          label: '데이터 이관',
          children: <DataTransferTab />,
          forceRender: true,
        },
      ];
    } else {
      return [
        {
          key: '1',
          label: '프로필 수정',
          children: <ProfileEditTab confirm={updateProfile} />,
          forceRender: true,
        },
      ];
    }
  };

  return (
    <div>
      <Flex justifyContent={'space-between'}>
        <h2 style={{ fontSize: 24, fontWeight: 'bold' }}>{menuTitle}</h2>
        {subscriptionStatus === false && <SubscriptStatusLabel />}
      </Flex>
      <Tab 
        items={tabItems()} 
        style={{ marginTop: 40 }}
        activeKey={activeKey} 
        onChange={onChangeTab}
      />
    </div>
  );
};

import React, { useId, useState } from 'react';
import { Button, CheckBox, Flex, MultiButton } from 'components/hulam_platform';
import './Login.css';
//import axios from "axios";
import FindIdModal from './modal/FindIdModal';
import { alert, callApi } from 'utils';
import FindPasswordModal from './modal/findPasswordModal';
import { useAuth } from 'AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'antd';

export const OperatorLogin = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [saveLogin, setSaveLogin] = useState(false);

  const [isFindPasswordModal, setIsFindPasswordModal] = useState(false);
  const [isFindIdModal, setIsFindIdModal] = useState(false);
  const { login } = useAuth();

  const navigate = useNavigate();

  /**@function  sendLoginRequest
   * 로그인 API 함수 호출 -> 업무 대행사
   * 로그인 성공시 login(response, type) 함수 호출 -> type은 "manager" (권한 체크)
   */
  const sendLoginRequest = async () => {
    setLoading(true);

    if (useId && password) {
      const postData = {
        userId: userId,
        password: password,
        // 추가 필드
      };

      const response = await callApi('api/platformLogin', postData);

      setLoading(false);

      if (response && response.result) {
        if (response.type === 'manager') {
          login(response, 'manager', saveLogin);
          navigate('/hulamHome', { state: 'initLogin' });
        }
        if (response.type === 'admin') {
          login(response, 'admin', saveLogin);
          navigate('/operatorCompanyList', { state: 'initLogin' });
        }
      } else if (response.mb_password_wrong_cnt > 5) {
        alert({
          content: (
            <div>
              비밀 번호 시도 횟수가 5회를 초과했습니다.
              <br /> 1시간 뒤에 다시 시도해 주세요.
            </div>
          ),
        });
      } else if (response.mb_password_wrong_cnt > 3) {
        alert({
          content: (
            <div>
              가입된 회원아이디가 아니거나 비밀번호가 틀립니다.비밀번호는 대소문자를 구분합니다.{' '}
              <br /> 총 5회 실패시에 1시간 동안 로그인을 할 수 없습니다.
              <br />
              <span style={{ color: 'red' }}>({response.mb_password_wrong_cnt}/5)</span>
            </div>
          ),
        });
      } else {
        console.log(response);
        alert({
          content: (
            <div>
              가입된 회원아이디가 아니거나 비밀번호가 틀립니다.비밀번호는 대소문자를 구분합니다.
              <br />
              <span style={{ color: 'red' }}>({response.mb_password_wrong_cnt}/5)</span>
            </div>
          ),
        });
      }
    }
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div
        className="register"
        style={{
          margin: '0px auto',
          padding: 209,
        }}
      >
        <section>
          <div className="section_inner">
            <div className="section_top">
              <img
                src="https://hulampro.co.kr/logo.png"
                className="section_img"
                alt="휴램 로고"
                height={30}
              />
              <h1 className="login_title">급여 아웃소싱의 혁신</h1>
            </div>
            <div className="section_info">
              <Form className="register_container">
                <li>
                  <Input
                    value={userId}
                    onChange={e => setUserId(e.target.value)}
                    placeholder="아이디"
                    style={{ width: 380, height: 50 }}
                  />
                </li>
                <li>
                  <Input.Password
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="비밀번호"
                    style={{ width: 380, height: 50 }}
                  />
                </li>
                <li style={{ margin: '14px 0' }}>
                  <Flex justifyContent="space-between">
                    <CheckBox
                      width="auto"
                      checked={saveLogin}
                      onChange={e => {
                        setSaveLogin(e.target.checked);
                      }}
                    >
                      로그인 상태 유지
                    </CheckBox>
                    <MultiButton>
                      {/*<Button*/}
                      {/*  type="text"*/}
                      {/*  onClick={() => setIsFindIdModal(true)}*/}
                      {/*  style={{ padding: 0, fontSize: 14, fontWeight: 400 }}*/}
                      {/*>*/}
                      {/*  아이디 찾기*/}
                      {/*</Button>*/}
                      <Button
                        type="text"
                        onClick={() => setIsFindPasswordModal(true)}
                        style={{ padding: 0, fontSize: 14, fontWeight: 400 }}
                      >
                        비밀번호 찾기
                      </Button>
                    </MultiButton>
                  </Flex>
                </li>
                <li>
                  <Button
                    htmlType="submit"
                    onClick={() => sendLoginRequest()}
                    style={{ width: '100%', height: 50 }}
                    isDisabled={loading ? true : false}
                  >
                    로그인
                  </Button>
                </li>
                <li style={{ margin: '14px 0' }}>
                  <span className="divider">
                    <span>아직 회원이 아니신가요?</span>
                  </span>
                </li>
                <li>
                  <Button href="/join" type="line-purple" style={{ width: '100%', height: 50 }}>
                    회원가입
                  </Button>
                </li>
              </Form>
            </div>
          </div>
        </section>
      </div>
      {isFindIdModal && (
        <FindIdModal open={isFindIdModal} handleCancel={() => setIsFindIdModal(false)} />
      )}
      {isFindPasswordModal && (
        <FindPasswordModal
          open={isFindPasswordModal}
          handleCancel={() => setIsFindPasswordModal(false)}
        />
      )}
    </div>
  );
};
